// import { useState } from 'react';
// import CMTrophyLogo from '../../../assets/img/cm-trophy/logos.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Img1 from '../../../assets/img/cm-trophy-home-page/Periyar (1) 1.png';
import Img2 from '../../../assets/img/cm-trophy-home-page/image 257.png';
import Img3 from '../../../assets/img/cm-trophy-home-page/image 258.png';

import useCustomStyling from 'hooks/useCustomStyling';
const HeaderLogo = () => {
  // const [currentImg] = useState(CMTrophyLogo);
  const { isMobile } = useCustomStyling();
  return (
    // <div>
    //   <img src={currentImg} className={'h-14 hidden lg:inline-block cursor-pointer'} alt={''} />
    //   {/* <img src={KheloIndiaLogo} className={'h-12 lg:hidden cursor-pointer'} alt={''} /> */}
    // </div>

    <Link to="/" className="flex items-center gap-2 justify-start">
      <img
        src={Img1}
        alt="Emblem"
        className=" rounded-full "
        style={{ width: isMobile ? '40px' : '60px', height: isMobile ? '40px' : '60px' }}
      />
      <img
        src={Img2}
        alt="SDAT Logo"
        className=""
        style={{ width: isMobile ? '40px' : '60px', height: isMobile ? '40px' : '60px' }}
      />
      <img
        src={Img3}
        alt="Stylized Logo"
        className=""
        style={{ width: isMobile ? '40px' : '60px', height: isMobile ? '40px' : '60px' }}
      />
    </Link>
  );
};

export default HeaderLogo;
