import React from 'react';

const CustomButton = ({
  type = 'button',
  onClick,
  children,
  style = {},
  className = '',
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`py-2 px-4 text-blue-500 border-2 border-blue-500 bg-gray-200 rounded-md cursor-pointer text-sm ${className}`}
      style={style}
    >
      {children}
    </button>
  );
};

export default CustomButton;
