import { useState, useEffect } from 'react';
import useWindowDimensions from './useWindowDimensions';

export default function useCustomStyling() {
  const { width } = useWindowDimensions();
  const [device, setDevice] = useState('pc');
  const [fontSize, setFontSize] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const sizes = {
    m: '16px',
    lg: '25px',
    xl: '40px',
  };

  useEffect(() => {
    if (width < 1028 && width > 550) {
      setIsTablet(true);
      setIsMobile(false);
    } else if (width < 550) {
      setDevice('mobile');
      setIsMobile(true);
      setFontSize(sizes.lg);
      setIsTablet(false);
    } else {
      setDevice('pc');
      setFontSize(sizes.xl);
      setIsMobile(false);
      setIsTablet(false);
    }
  }, [width]);

  return { device, fontSize, isMobile, isTablet };
}
