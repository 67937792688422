import React from 'react';
import Select from 'react-select';

const CustomDropdown = ({
  value,
  onChange,
  options,
  placeholder = 'Select an option',
  required = false,
}) => {
  const formattedOptions = options.map(option => ({
    value: option.id || option.name || option,
    label: option.tournament_name || option.name || option,
  }));

  return (
    <Select
      value={formattedOptions.find(opt => opt.value === value)}
      onChange={(selectedOption) => onChange(selectedOption ? selectedOption.value : '')}
      options={formattedOptions}
      placeholder={placeholder}
      isClearable={!required}
      classNamePrefix="react-select"
      styles={{
        control: (provided) => ({
          ...provided,
          cursor: 'pointer',
          borderRadius: '0.5rem',
          borderColor: '#d1d5db',
          boxShadow: 'none',
          '&:hover': {
            borderColor: '#60a5fa',
          },
        }),
      }}
    />
  );
};

export default CustomDropdown;
