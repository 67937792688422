// change tournament here
export const tournament = location.pathname.split('/')[2];

export const form = {
  components: [
    {
      label: 'Columns',

      columns: [
        {
          components: [
            {
              label: 'Sport',
              widget: 'choicesjs',
              tableView: true,
              dataSrc: 'url',
              data: {
                url: `/api/method/sfa_online.v1.digital_scoring.get_sports?tournament=${tournament}`,
                headers: [
                  {
                    key: '',
                    value: '',
                  },
                ],
              },
              dataType: 'string',
              idPath: '',
              validate: {
                required: true,
              },
              key: 'sport',
              type: 'select',
              selectValues: 'message',
              input: true,
              disableLimit: false,
            },
          ],
          width: 6,
          offset: 0,
          push: 0,
          pull: 0,
          size: 'md',
          currentWidth: 6,
        },

        {
          components: [
            {
              label: 'Gender',
              widget: 'choicesjs',
              tableView: true,
              dataSrc: 'url',
              data: {
                url: `/api/method/sfa_online.v1.digital_scoring.get_gender?tournament=${tournament}&sport={{data.sport}}`,
                headers: [
                  {
                    key: '',
                    value: '',
                  },
                ],
              },
              dataType: 'string',
              idPath: '',
              refreshOn: 'sport',
              clearOnRefresh: true,
              validate: {
                required: true,
              },
              key: 'gender',
              type: 'select',
              selectValues: 'message',
              input: true,
              disableLimit: false,
            },
          ],
          width: 3,
          offset: 0,
          push: 0,
          pull: 0,
          size: 'md',
          currentWidth: 3,
        },

        {
          components: [
            {
              label: 'Age Group',
              widget: 'choicesjs',
              tableView: true,
              dataSrc: 'url',
              data: {
                url: `/api/method/sfa_online.v1.digital_scoring.get_age_group?tournament=${tournament}&sport={{data.sport}}&gender={{data.gender}}`,
                headers: [
                  {
                    key: '',
                    value: '',
                  },
                ],
              },
              dataType: 'string',
              idPath: '',
              refreshOn: 'gender',
              clearOnRefresh: true,
              validate: {
                required: true,
              },
              key: 'age_group',
              type: 'select',
              selectValues: 'message',
              input: true,
              disableLimit: false,
            },
          ],
          size: 'md',
          width: 3,
          offset: 0,
          push: 0,
          pull: 0,
          currentWidth: 3,
        },
      ],
      key: 'columns',
      type: 'columns',
      input: false,
      tableView: false,
    },

    {
      label: 'Columns',
      columns: [
        {
          components: [
            {
              label: 'Sport Category',
              widget: 'choicesjs',
              tableView: true,
              dataSrc: 'url',
              data: {
                url: `/api/method/sfa_online.v1.digital_scoring.get_sport_category?tournament=${tournament}&sport={{data.sport}}&gender={{data.gender}}&age_group={{data.age_group}}`,
                headers: [
                  {
                    key: '',
                    value: '',
                  },
                ],
              },
              dataType: 'string',
              idPath: '',
              refreshOn: 'age_group',
              clearOnRefresh: true,
              validate: {
                required: true,
              },
              key: 'sport_category',
              type: 'select',
              selectValues: 'message',
              input: true,
              disableLimit: false,
            },
          ],
          width: 6,
          offset: 0,
          push: 0,
          pull: 0,
          size: 'md',
          currentWidth: 6,
        },

        {
          components: [
            {
              label: 'Sub Category',
              widget: 'choicesjs',
              tableView: true,
              dataSrc: 'url',
              data: {
                url: `/api/method/sfa_online.v1.digital_scoring.get_sub_category?tournament=${tournament}&sport={{data.sport}}&gender={{data.gender}}&age_group={{data.age_group}}&sport_category={{data.sport_category}}`,
                headers: [
                  {
                    key: '',
                    value: '',
                  },
                ],
              },
              dataType: 'string',
              idPath: '',
              refreshOn: 'sport_category',
              clearOnRefresh: true,
              validate: {
                required: true,
              },
              key: 'sub_category',
              type: 'select',
              selectValues: 'message',
              input: true,
              disableLimit: false,
            },
          ],
          width: 6,
          offset: 0,
          push: 0,
          pull: 0,
          size: 'md',
          currentWidth: 6,
        },
      ],
      key: 'columns1',
      type: 'columns',
      input: false,
      tableView: false,
    },

    {
      label: 'View Draws & Schedules',
      action: 'submit',
      className:"bg-blue-100",
      showValidations: false,
      disableOnInvalid: true,
      tableView: false,
      key: 'submit',
      type: 'button',
      headers: [
        {
          header: '',
          value: '',
        },
      ],
      input: true,
    },
  ],
};
