import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../assets/img/cm-trophy-home-page/Alt Arrow Down.svg';
import Img1 from '../../../assets/img/cm-trophy-home-page/secondaryHeader1.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ReactComponent as HamSvg } from '../../../assets/img/cm-trophy-home-page/Hamburger Menu.svg';
import useCustomStyling from 'hooks/useCustomStyling';
import { ReactComponent as ArrowUp } from '../../../assets/img/cm-trophy-home-page/Alt Arrow Up.svg';
import { ReactComponent as Expand } from '../../../assets/img/cm-trophy-home-page/down.svg';
import { ReactComponent as Close } from '../../../assets/img/cm-trophy-home-page/Close Circle.svg';
import { useHistory } from 'react-router-dom';

function SecondaryHeader({ showOptions }) {
  const [activeHover, setActiveHover] = useState(0);
  const { isMobile, isTablet } = useCustomStyling();
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const closeSideBar = () => setMenuOpen(false);

  const SUB_MENU_ITEMS = [
    {
      label: 'Home',
      path: '/',
    },
    {
      label: 'Medal Tally',
      path: '/micro-site/242/landing/results',
    },
    {
      label: 'Schedule',
      menus: [
        {
          label: 'School',
          path: '/micro-site/242/landing/schedule',
        },
        {
          label: 'College',
          path: '/micro-site/243/landing/schedule',
        },
        {
          label: 'Differently Abled',
          path: '/micro-site/244/landing/schedule',
        },
        {
          label: 'General Public',
          path: '/micro-site/246/landing/schedule',
        },
        {
          label: 'Government Employees',
          path: '/micro-site/245/landing/schedule',
        },
      ],
    },
    // {
    //   label: 'Venues',
    //   menus: [
    //     {
    //       label: 'School',
    //       path: '/micro-site/242/landing/venues',
    //     },
    //     {
    //       label: 'College',
    //       path: '/micro-site/243/landing/venues',
    //     },
    //     {
    //       label: 'Differently Abled',
    //       path: '/micro-site/244/landing/venues',
    //     },
    //     {
    //       label: 'Government Employees',
    //       path: '/micro-site/245/landing/venues',
    //     },
    //     {
    //       label: 'General Public',
    //       path: '/micro-site/246/landing/venues',
    //     },
    //   ],
    // },
    {
      label: 'Sports',
      menus: [
        {
          label: 'School',
          path: '/micro-site/242/landing/sports',
        },
        {
          label: 'College',
          path: '/micro-site/243/landing/sports',
        },
        {
          label: 'Differently Abled',
          path: '/micro-site/244/landing/sports',
        },
        {
          label: 'Government Employees',
          path: '/micro-site/245/landing/sports',
        },
        {
          label: 'General Public',
          path: '/micro-site/246/landing/sports',
        },
      ],
    },
    {
      label: 'Results',
      menus: [
        {
          label: 'School',
          path: '/micro-site/242/landing/draws-schedules',
        },
        {
          label: 'College',
          path: '/micro-site/243/landing/draws-schedules',
        },
        {
          label: 'Differently Abled',
          path: '/micro-site/244/landing/draws-schedules',
        },
        {
          label: 'Government Employees',
          path: '/micro-site/245/landing/draws-schedules',
        },
        {
          label: 'General Public',
          path: '/micro-site/246/landing/draws-schedules',
        },
      ],
    },

    {
      label: 'Gallery',
      // path: '/Gallery',
      path: '/micro-site/242/landing/gallery',
    },
    {
      label: 'News',
      path: '/news',
    },
    // {
    //   label: 'About Us',
    //   path: '#about-us',
    // },
  ];

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
  }, [menuOpen]);

  return (
    <>
      <div
        style={{ backgroundColor: '#0F3B84', borderColor: '#496A9F', height: '60px' }}
        className="border-t flex gap-5"
      >
        <div className={' flex items-center justify-start'}>
          <img src={Img1} alt="logo" className="h-full" />
        </div>
        <div className="flex-1 hidden lg:flex items-center justify-center gap-4">
          {SUB_MENU_ITEMS.map((e, i) => {
            return (
              <div
                key={i}
                className="relative h-full flex flex-1 items-center justify-center cursor-pointer "
                style={{ borderColor: '#496A9F' }}
                onMouseEnter={() => setActiveHover(i + 1)}
                onMouseLeave={() => setActiveHover(0)}
              >
                {e.menus && e.menus?.length > 0 ? (
                  <p
                    className={`text-center font-poppins font-light text-white `}
                    style={{ fontSize: '16px' }}
                  >
                    {e.label}
                  </p>
                ) : (
                  <a href={e.path}>
                    <p
                      className={`text-center font-poppins font-light text-white `}
                      style={{ fontSize: '16px' }}
                    >
                      {e.label}
                    </p>
                  </a>
                )}
                {e.menus && <ArrowDown />}

                {activeHover === i + 1 && e?.menus?.length > 0 && (
                  <div
                    className="absolute  bg-white flex flex-col z-10"
                    style={{ top: '100%', minWidth: '240px' }}
                  >
                    {e.menus.map((menu, index) => {
                      return (
                        <Link
                          to={menu.path}
                          key={index}
                          className=" text-blue-landingmenu hover:bg-blue-landingmenuBg text-center font-poppins flex items-center justify-center border-t whitespace-nowrap"
                          style={{ height: '60px', fontSize: '16px' }}
                        >
                          {menu.label}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}

          {/* {showOptions&& <Link to="/signin" className={'flex items-center justify-center p-3 px-4'}>
        <p className="text-[16px] font-light text-gray-15 hover:text-gray-20 ignore-style font-poppins">
          Login
        </p>
      </Link>} */}
        </div>
        <div className={isTablet || isMobile ? 'flex-1 justify-end flex items-center' : 'min-w-18'}>
          {(isTablet || isMobile) && (
            <div className="flex-1 flex items-center justify-end gap-2 px-2">
              {
                window.location.pathname !== '/micro-site/242/landing/results' &&
                (
                  <button
                    className="border p-2 text-white font-light font-poppins text-xss whitespace-pre-wrap text-center lg:hidden"
                    style={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      fontSize: '0.6rem',
                      borderRadius: '12px',
                    }}
                    onClick={() => {
                      history.push('/micro-site/242/landing/results');
                    }}
                  >
                    Medal Tally
                  </button>
                )}

              {window.location.pathname !== '/access-profile' && (
                <button
                  className="border p-2 text-white font-light font-poppins text-center lg:hidden"
                  style={{
                    // truncate text
                    whiteSpace: 'nowrap',
                    borderColor: 'rgba(255, 255, 255, 0.2)',
                    fontSize: '0.6rem',
                    borderRadius: '12px',
                  }}
                  onClick={() => {
                    history.push('/access-profile');
                  }}
                >
                  Access Player Profile
                </button>
              )}
              <button
                className={`${menuOpen ? 'h-full' : ''}`}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                {menuOpen ? <Close /> : <HamSvg />}
              </button>
            </div>
          )}
        </div>
      </div>

      {(isMobile || isTablet) && (
        <div
          className={`fixed w-full flex z-10 h-screen transition-all  ${!menuOpen ? '' : 't'
            } bg-white`}
          style={{ top: 139, transform: menuOpen ? 'translateX(0)' : 'translateX(100%)' }}
        >
          <div
            className={`h-full flex-1 `}
            style={{ backgroundColor: 'rgb(0 0 0 / 58%)' }}
            onClick={closeSideBar}
          ></div>
          <div
            className="w-full flex flex-col min-h-full overflow-y-auto"
            style={{ maxWidth: '350px' }}
          >
            {SUB_MENU_ITEMS.map((e, i) => {
              return (
                <div key={i} className="flex flex-col w-full border-t" style={{ fontSize: '16px' }}>
                  <div
                    className="w-full h-full flex items-center justify-between px-4"
                    style={{
                      minHeight: '60px',
                      backgroundColor: activeHover === i + 1 ? 'rgba(255, 206, 20, 1)' : '',
                      fontSize: '16px',
                    }}
                    onClick={() => {
                      if (e.menus && e.menus?.length > 0) {
                        if (activeHover === i + 1) {
                          return setActiveHover(0);
                        }
                        setActiveHover(i + 1);
                      } else {
                        history.push(e.path);
                      }
                    }}
                  >
                    <span className="hover:text-white text-blue-landingmenu hover:bg-blue-landingmenuBg font-poppins flex">
                      {e.label}
                    </span>

                    {e.menus ? activeHover === i + 1 ? <ArrowUp /> : <Expand /> : null}
                  </div>

                  {activeHover === i + 1 && e?.menus?.length > 0 && (
                    <div
                      className=" w-full flex flex-col items-start"
                      style={{ backgroundColor: 'rgba(255, 206, 20, 0.16)' }}
                    >
                      {e.menus.map((menu, index) => {
                        return (
                          <a
                            href={menu.path}
                            key={index}
                            className="  font-poppins px-6 flex items-center w-full border-t"
                            style={{ height: '60px', fontSize: '16px', color: '#0F3B84' }}
                          >
                            <span className="hover:text-white text-blue-landingmenu hover:bg-blue-landingmenuBg font-poppins">
                              {menu.label}
                            </span>
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default SecondaryHeader;
