import { FaTwitter, FaYoutube, FaInstagram, FaFacebook } from 'react-icons/fa';
import FooterBg from 'assets/img/cm-trophy/footer-bg.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useCustomStyling from 'hooks/useCustomStyling';
import one from '../../../assets/img/cm-trophy/1.png';
import two from '../../../assets/img/cm-trophy/2.png';
import three from '../../../assets/CM Trophy Logo.png';
import poweredByLogo from 'assets/img/poweredByLogo.png';

export default function Footer() {
  const { isMobile } = useCustomStyling();
  return (
    <footer
      className="bg-cm-blu text-white pt-6 relative overflow-hidden font-poppins"
      style={{ backgroundColor: '#0F3B84' }}
    >
      <div
        className="grid grid-cols-1 md:grid-cols-2 mx-auto "
        style={{ paddingLeft: isMobile ? 10 : 120 }}
      >
        <div className="w-full ">
          <div className="flex items-center mb-4 w-full">
            {/* <img src="/logo1.png" alt="Tamil Nadu Logo" width={60} height={60} className="mr-4" /> */}
            {/* <img src="/logo2.png" alt="SDAT Logo" width={60} height={60} /> */}
            <Link to="/">
              <div className="flex items-center space-x-4">
                <img
                  src={one}
                  alt="Emblem"
                  className=" rounded-full "
                  style={{ width: isMobile ? '60px' : '80px', height: isMobile ? '60px' : '80px' }}
                />
                <img
                  src={two}
                  alt="SDAT Logo"
                  className=""
                  style={{ width: isMobile ? '60px' : '80px', height: isMobile ? '60px' : '80px' }}
                />
                <img
                  src={three}
                  alt="Stylized Logo"
                  className=""
                  style={{ width: isMobile ? '60px' : '80px', height: isMobile ? '60px' : '80px' }}
                />
              </div>
            </Link>
          </div>
          <div>
            <h2 className=" font-medium my-6 font-poppins">
              Sports Development Authority of Tamil Nadu
            </h2>
            <p className="mb-1 font-poppins text-sm ">Jawaharlal Nehru Stadium,</p>
            <p className="mb-1 font-poppins text-sm">Raja Muthiah Road, Periyamet,</p>
            <p className="mb-1 font-poppins text-sm">Chennai - 600003</p>
            <p className="mb-1 font-poppins text-sm">Tamil Nadu</p>
            <p className="mt-6 mb-2 font-poppins text-sm">
              <a
                href="mailto:sdat@tn.gov.in"
                className="hover:underline text-gray-30 ignore-style font-poppins font-bold "
              >
                sdat@tn.gov.in
              </a>
            </p>
            <p>
              <a
                href="tel:9514000777"
                className="hover:underline text-gray-30 ignore-style font-poppins font-bold"
              >
                9514000777
              </a>
            </p>
          </div>
        </div>

        <div className="container flex flex-wrap justify-between" style={{ paddingTop: '70px' }}>
          <div className="w-full hidden">
            <h3 className=" font-semibold mb-4 font-poppins" style={{ fontSize: '24px' }}>
              Links
            </h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="https://www.sdat.tn.gov.in/"
                  target={'_blank'}
                  className="hover:underline text-gray-30 ignore-style  font-poppins text-sm "
                  rel="noreferrer"
                >
                  The Ministry of Youth Affairs and Sports
                </a>
              </li>
              <li>
                <a
                  href="https://www.sdat.tn.gov.in/"
                  target={'_blank'}
                  className="hover:underline text-gray-30 ignore-style  font-poppins text-sm "
                  rel="noreferrer"
                >
                  The Sports Authority of India
                </a>
              </li>
              <li>
                <a
                  href="https://www.sdat.tn.gov.in/"
                  target={'_blank'}
                  className="hover:underline text-gray-30 ignore-style  font-poppins text-sm "
                  rel="noreferrer"
                >
                  The Government of Tamil Nadu
                </a>
              </li>
              <li>
                <a
                  href="https://www.sdat.tn.gov.in/"
                  target={'_blank'}
                  className="hover:underline text-gray-30 ignore-style  font-poppins text-sm "
                  rel="noreferrer"
                >
                  Tamil Nadu Physical Education and Sports University
                </a>
              </li>
            </ul>
          </div>
          <div className="  flex space-x-4 mt-6">
            <a
              href="https://x.com/sportstn_"
              target={'_blank'}
              aria-label="Twitter"
              className={'text-gray-30 ignore-style'}
              rel="noreferrer"
            >
              <FaTwitter className="text-2xl" />
            </a>
            <a
              href="https://www.youtube.com/@sportstamilnadu?si=Bue9wtNtVzSO8k38"
              target={'_blank'}
              aria-label="YouTube"
              className={'text-gray-30 ignore-style'}
              rel="noreferrer"
            >
              <FaYoutube className="text-2xl " />
            </a>
            <a
              href="https://www.instagram.com/sportstn_?igsh=NnVkNjNrbDAyNWRq"
              target={'_blank'}
              aria-label="Instagram"
              className={'text-gray-30 ignore-style'}
              rel="noreferrer"
            >
              <FaInstagram className="text-2xl " />
            </a>
            <a
              href="https://www.facebook.com/sdat.gov"
              aria-label="Facebook"
              target={'_blank'}
              className={'text-gray-30 ignore-style'}
              rel="noreferrer"
            >
              <FaFacebook className="text-2xl " />
            </a>
          </div>
        </div>
      </div>
      <div className="absolute top-0 right-0 w-1/3 h-full opacity-10">
        <img src={FooterBg} alt="" className={'object-cover'} />
      </div>

      <div
        className="grid grid-cols-1 md:grid-cols-2 mx-auto items-center py-4 mt-4 border-t"
        style={{ paddingLeft: isMobile ? 10 : 120, borderColor: 'rgba(255, 255, 255, 0.2)' }}
      >
        <div className="w-96 flex items-center">
          <p className="font-poppins font-light mr-2" style={{ fontSize: '12px' }}>
            Powered by
          </p>
          <img src={poweredByLogo} alt="Powered by SFA" className="h-4" />
        </div>
        <p className={'w-150 font-poppins font-light'} style={{ fontSize: '12px' }}>
          Copyright 2024 Sports Development Authority of Tamil Nadu
        </p>
      </div>
    </footer>
  );
}
