import React from 'react';
import CMTrophyHeader from '../../micro-site/Components/CMTrophyHeader';
import Footer from '../../micro-site/Components/footer/Footer';
import Loader from '../Loader';
import useCustomStyling from '../../hooks/useCustomStyling';
import { useHomePageNewsList } from '../../micro-site/queries/hooks';

const NewsPage = () => {
  const { fontSize, isMobile } = useCustomStyling();
  const { data: news, isLoading: isNewsLoading } = useHomePageNewsList();

  return (
    <div className={'w-screen '}>
      <CMTrophyHeader />
      <section
        className="w-full flex flex-col"
        style={{ marginTop: 56, marginBottom: 56 }}
        id="sports"
      >
        <h3
          className=" font-bold italic font-poppins  text-center uppercase"
          style={{ color: '#0D0D0D', fontSize: fontSize, marginBottom: isMobile ? 32 : 64 }}
        >
          In The News
        </h3>
        <div className='w-11/12 m-auto'>
          <div
            className="grid grid-cols-2 md:grid-cols-3 justify-center gap-4 p-4  mx-auto"
            style={{ columnGap: 43, rowGap: 60 }}
          >
            {isNewsLoading && <Loader />}
            {!isNewsLoading && news?.length > 0 ? (
              news?.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="flex flex-col gap-1"
                    onClick={() => {
                      window.open(e.link);
                    }}
                  >
                    <div className='h-3/4'>
                      <img src={e?.cover_image} alt={''} className="w-full h-full cursor-pointer" />
                    </div>
                    <p
                      className="font-poppins font-semibold w-full cursor-pointer"
                      style={{ fontSize: isMobile ? 10 : 16, color: '#000B27' }}
                    >
                      {e.title}
                    </p>
                  </div>
                );
              })
            ) : (
              <div className={'m-auto'}>News is coming soon</div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default NewsPage;
