import search from '../../assets/videos/Search.mp4';
import gold from '../../assets/img/gold.svg';
import silver from '../../assets/img/silver.svg';
import bronze from '../../assets/img/bronze.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faCalendarWeek,
  faMars,
  faVenus,
} from '@fortawesome/free-solid-svg-icons';
import { getDateInMonthDateYear, getTimeInHHMM } from '../../constants/DateFunctions.js';
import { useRef, useState } from 'react';
import './Table.css';
import { useInfiniteScroll, useShowKhelo } from 'utils/HooksUtils';
// import { getTime } from 'constants/DateFunctions';
import { calculateConditions } from 'micro-site/Landing/DrawsAndSchedule';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import AdvancedDsComponent from '../../components/AdvancedDsComponent/AdvancedDsComponent';
import { matchStatus, realTimeStatus, yetToPlayStatus } from '../../utils/constants';

function TextColumn({ row, columnKey, selected, _setSelected, index }) {
  const isKhelo = useShowKhelo();
  if (columnKey === 'sfa_id_1')
    return <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400">{row && row?.opponent_1.sfa_id}</td>;

  if (columnKey === 'sfa_id_2')
    return <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400">{row && row?.opponent_2.sfa_id}</td>;

  if (
    columnKey === 'gold' ||
    columnKey === 'silver' ||
    columnKey === 'bronze' ||
    columnKey === 'points'
  )
    return (
      <td
        className="text-xs py-2.5"
        style={{ color: row.points[columnKey] === 0 ? '#132445' : '#132445' }}
      >
        <div
          className={`text-center leading-10 px-2.5 b md:px-0 ${index === selected ? (!isKhelo ? 'bg-blue-450' : 'bg-yellow-kheloDarkYellow') : ''
            }`}
        >
          {row.points[columnKey]}
        </div>
        {selected ? (
          <div className={`bg-yellow-kheloDarkYellow ${selected === index ? 'py-2' : ''}`}>
            {row.sports.map((sp, i) => (
              <div
                key={i}
                className={`text-xs text-center ${selected === index ? 'leading-8 pb-2' : ''}`}
                style={{ color: row.points[columnKey] === 0 ? '#132445' : '#132445' }}
              >
                {' '}
                {index === selected && sp[columnKey]}{' '}
              </div>
            ))}
          </div>
        ) : null}
      </td>
    );

  if (columnKey === 'sports' || columnKey === 'age_group' || columnKey === 'sub_category')
    return <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400">{row?.sport_event[columnKey] || '-'}</td>;

  if (columnKey === 'rank')
    return (
      <td className="text-xs text-blue-kheloBlue400 py-2.5">
        <p
          className={`leading-10 ${selected === index ? (!isKhelo ? 'bg-blue-450' : 'bg-yellow-kheloDarkYellow') : ''
            }  pl-4`}
        >
          {row[columnKey] < 10 ? '0' + row[columnKey] : row[columnKey]}
        </p>
        {selected ? (
          <div className={`bg-yellow-kheloDarkYellow  ${selected === index ? 'pb-4' : ''}`}>
            {row.sports.map((sp, i) => (
              <div
                key={i}
                className={`text-xs text-blue-kheloBlue400 ${selected === index ? 'pl-4 pb-2' : ''}`}
              >
                <p className="text-blue-kheloBlue400 leading-8">{index === selected ? '\xa0' : null}</p>
              </div>
            ))}
          </div>
        ) : null}
      </td>
    );
  if (columnKey === 'sport')
    return <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400">{row?.sport_event?.sport}</td>;

  return <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400">{row[columnKey]}</td>;
}

function TextAndImage({ row, columnKey, selected, _setSelected, index }) {
  if (columnKey === 'medal')
    return (
      <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400">
        {row[columnKey].toLocaleLowerCase() === 'gold' ? (
          <img src={gold} width="22px" height="22px" alt="" className="pr-2 inline-block" />
        ) : (
          ''
        )}
        {row[columnKey].toLocaleLowerCase() === 'silver' ? (
          <img src={silver} width="22px" height="22px" alt="" className="pr-2 inline-block" />
        ) : (
          ''
        )}
        {row[columnKey].toLocaleLowerCase() === 'bronze' ? (
          <img src={bronze} width="22px" height="22px" alt="" className="pr-2 inline-block" />
        ) : (
          ''
        )}

        {row[columnKey]}
      </td>
    );

  return (
    <td className="py-2.5 text-xs text-blue-kheloBlue400">
      {columnKey === 'sport_event' ? (
        <div className="flex gap-2 items-center pl-4">
          {row[columnKey].gender === 'Male' ? (
            <FontAwesomeIcon icon={faMars} className="w-3.5 h-3.5 text-blue-400" />
          ) : (
            <FontAwesomeIcon icon={faVenus} className="w-3.5 h-3.5 text-red-350" />
          )}
          <span>{row[columnKey]?.gender}</span>
        </div>
      ) : (
        <div
          className={`flex whitespace-normal gap-x-2 px-4 md:px-4 items-center lg:w-96 break-words h-10 overflow-y-hidden ${index === selected ? 'bg-yellow-kheloDarkYellow' : ''
            }`}
        >
          <div
            src={row[columnKey]?.image}
            alt="dp"
            style={{
              background: `url('${row[columnKey]?.image}')`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            className="w-8 h-8 rounded-full hidden md:inline-block"
          />
          <div className="flex gap-1 max-w-lg md:max-w-none">
            <p className="md:hidden">{row.rank}.</p>
            <p className="">
              {row[columnKey]?.name}
              {/* {!row[columnKey]?.registered ? '*' : ''}   */}
            </p>
          </div>
        </div>
      )}
      {selected ? (
        <div className={`bg-yellow-kheloDarkYellow ${selected === index ? 'py-2 pl-4' : ''}`}>
          {row.sports.map((sp, i) => (
            <div
              key={i}
              className={`text-xs text-blue-kheloBlue400 flex gap-3 items-center ${selected === index ? 'leading-8 pb-2' : ''
                }`}
            >
              {index === selected && <img src={sp.image} width="25px" height="25px" alt="dp" />}
              <p className="text-center leading-8">{index === selected && sp.name}</p>
            </div>
          ))}
        </div>
      ) : null}
    </td>
  );
}

function ResultButton({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400">
      {row[columnKey].status !== 'Bronze' &&
        row[columnKey].status !== 'Silver' &&
        row[columnKey].status !== 'Gold' ? (
        <button
          className={`${row[columnKey].status === 'Qualified'
              ? 'bg-green-450 text-blue-kheloBlue400'
              : 'bg-gray-300 text-gray-500'
            }  rounded-md w-28 px-4 py-1`}
        >
          {row[columnKey].status}
        </button>
      ) : (
        <button className="flex gap-2">
          <img src={row[columnKey].medal} alt="Medal" />
          <p>{row[columnKey].status}</p>
        </button>
      )}
    </td>
  );
}
function VideoColumn({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400">
      <div className="text-sm text-gray-900 h-8 w-34 overflow-hidden rounded-md cursor-pointer">
        <video>
          <source src={row[columnKey] ? row[columnKey] : search} />
        </video>
      </div>
    </td>
  );
}
function OpponentColumn({ row, columnKey, searchTerm }) {
  function getHighlightedText(text, searchText) {
    if (text === null || typeof text !== 'string') return '';
    const idx = text.toLowerCase().search(searchText.toLowerCase());
    if (
      idx === -1 ||
      text?.substring(idx, idx + searchText.length).toLowerCase() !== searchText.toLowerCase()
    ) {
      return text;
    } else {
      return (
        <span>
          {text.substring(0, idx)}
          <span className="text-orange-kheloOrange">
            {text.substring(idx, idx + searchText.length)}
          </span>
          {text.substring(idx + searchText.length)}
        </span>
      );
    }
  }
  const isKhelo = useShowKhelo();
  if (row[columnKey]?.name === 'NA') {
    return (
      <td data-cy="opponenet_na" className="pl-6 py-2.5 text-xs text-blue-kheloBlue400">
        NA
      </td>
    );
  }

  if (columnKey === 'athlete_sfa_id')
    return (
      <td
        className={`pl-4 py-2.5 text-xs w-1/3 text-blue-kheloBlue400 ${row[columnKey]?.bg_blue ? 'bg-blue-50' : ''
          }`}
      >
        <div className="flex items-center">
          <div
            src={row?.athlete?.image}
            alt="dp"
            style={{
              background: `url('${row?.athlete?.image}')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            className="w-8 h-8 rounded-full inline-block mr-3 flex-shrink-0"
          />
          <div>
            <div className="flex gap-1">
              <span className="font-semibold">{row?.athlete?.name}</span>
              <span className="text-blue-kheloBlue400 text-opacity-40">{row?.athlete?.sfa_id}</span>
            </div>
            <span className="text-blue-kheloBlue400 text-opacity-40">{row?.athlete?.institution}</span>
          </div>
        </div>
      </td>
    );

  return (
    <td className={`pl-4 py-2.5 text-xs text-blue-kheloBlue400 ${row[columnKey]?.bg_blue ? 'bg-blue-50' : ''}`}>
      <div className="flex items-center">
        <div
          src={row?.athlete?.image}
          alt="dp"
          style={{
            background: `url('${row[columnKey].image}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          className={`w-8 h-8 rounded-full flex-shrink-0 inline-block mr-3 ${row?.match?.match_status?.toLowerCase() === 'bye'
              ? 'border-2 border-blue-950'
              : row[columnKey].won === 1
                ? 'border-2 border-green-450'
                : ''
            } ${row?.match?.match_status?.toLowerCase() === 'bye'
              ? 'border-2 border-blue-950'
              : row[columnKey].won === 0
                ? 'border-2 border-red-350'
                : ''
            }`}
        />
        <div>
          <div className="flex items-center gap-1">
            <span data-cy="opponenet_name" className="font-semibold">
              {isKhelo && row?.[columnKey]?.name.split('-')?.[0] === 'Team'
                ? getHighlightedText(row[columnKey]?.institution, searchTerm, true)
                : getHighlightedText(row[columnKey]?.name, searchTerm)}
            </span>
            <span data-cy="opponenet_result" className="text-gray-500 text-mini">
              {/* {row[columnKey]?.score} */}
              {row.opponent_1.won !== null || row?.match?.match_status?.toLowerCase() === 'draw' ? (
                row[columnKey]?.won === 1 ? (
                  <p
                    className={`${row?.match?.match_status?.toLowerCase() === 'bye'
                        ? 'bg-blue-400'
                        : 'bg-green-450'
                      } text-white py-0.5 px-1.5 rounded `}
                  >
                    {' '}
                    {calculateConditions(row[columnKey]?.won, row.match.match_status)}
                  </p>
                ) : row[columnKey]?.won === 'Draw' ||
                  row?.match?.match_status?.toLowerCase() === 'draw' ? (
                  <p className="bg-gray-500 text-white py-0.5 px-1.5 rounded opacity-40">DRAW</p>
                ) : (
                  ''
                )
              ) : null}

              {row.opponent_2.won !== null || row?.match?.match_status?.toLowerCase() === 'draw' ? (
                row[columnKey]?.won === 0 ? (
                  <p
                    className={`${row?.match?.match_status?.toLowerCase() === 'bye'
                        ? 'bg-blue-950'
                        : 'bg-red-400'
                      } text-white py-0.5 px-1.5 rounded`}
                  >
                    {calculateConditions(row[columnKey]?.won, row.match.match_status)}
                  </p>
                ) : (
                  ''
                )
              ) : null}
            </span>
          </div>
          <span data-cy="opponenet_institution" className="opacity-40">
            {isKhelo && row?.[columnKey]?.name.split('-')?.[0] === 'Team'
              ? ''
              : getHighlightedText(row[columnKey]?.institution, searchTerm, true)}
          </span>
        </div>
      </div>
    </td>
  );
}

function DateTimeColumn({ row, _columnKey }) {
  if (row?.match?.match_status?.toLowerCase() !== 'bye')
    return (
      <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400 flex flex-col">
        <span data-cy="match_date">{getDateInMonthDateYear(row.match.date)}</span>
        <span data-cy="match_time">{getTimeInHHMM(row.match.time)}</span>
      </td>
    );
  return (
    <td className="pl-4 py-2.5 text-blue-kheloBlue400 text-xs">
      <p>-</p>
    </td>
  );
}

function ResultMedalButton({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-xs text-blue-kheloBlue400">
      {row[columnKey]?.medal === 'gold' ? (
        <>
          <img src={gold} alt="gold" className="inline-block w-5 h-5 mr-2" />
          <span>Gold (1st)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === 'silver' ? (
        <>
          <img src={silver} alt="silver" className="inline-block w-5 h-5 mr-2" />
          <span>Silver (2nd)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey]?.medal === 'bronze' ? (
        <>
          <img src={bronze} alt="bronze" className="inline-block w-5 h-5 mr-2" />
          <span>Bronze (3rd)</span>
        </>
      ) : (
        ''
      )}
      {row[columnKey].medal === '' || row[columnKey].medal === 'participated' ? (
        <>
          <span>Participated</span>
        </>
      ) : (
        ''
      )}
    </td>
  );
}

function SportColumn({ row, _columnKey, showSubCategory }) {
  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      to={`/public/score/${row?.event_format?.toLowerCase()}/${encodeURIComponent(row.event_name)}`}
    >
      <td className="pl-4 py-2.5 text-blue-kheloBlue400 items-center">
        <p data-cy="sport_event_sport" className="text-xs">
          {row.sport_event.sport}
          {showSubCategory && row?.sport_event?.sub_category
            ? `-${row?.sport_event?.sub_category}`
            : null}
        </p>
        <span className="flex gap-2">
          <span className="flex gap-1 items-center text-xxs bg-yellow-kheloDarkYellow py-0.5 px-1 rounded text-blue-kheloBlue400">
            <FontAwesomeIcon
              icon={row.sport_event.gender === 'Male' ? faMars : faVenus}
              className="w-2.5 h-2.5"
            />
            <p data-cy="sport_event_gender">{row.sport_event.gender}</p>
          </span>
          <span className="flex gap-1 items-center text-xxs bg-orange-highlight py-0.5 px-1 rounded text-white">
            <FontAwesomeIcon icon={faCalendarWeek} className="w-2.5 h-2.5" />
            <p data-cy="sport_event_age_group">{row.sport_event.age_group}</p>
          </span>
        </span>
      </td>
    </Link>
  );
}

function VenueColumn({ row, columnKey }) {
  return (
    <td className="pl-4 py-2.5 text-blue-kheloBlue400 text-xs whitespace-pre-wrap">
      <p>{row.match[columnKey]}</p>
    </td>
  );
}

function RoundColumn({ row, columnKey }) {
  return (
    <td className={`pl-4 py-2.5 text-blue-kheloBlue400 text-xs whitespace-pre-wrap`}>
      <p>{row[columnKey].round}</p>
      {/* <p className="text-gray-400">{row[columnKey].format || ''}</p> */}
    </td>
  );
}

function ScoreColumn({ row, columnKey, status, enableLink, _tournamentId }) {
  const [showAdvanceScoring, setShowAdvanceScoring] = useState(false);
  const links = {
    'heats / time trials': true,
    'qualifying round (high jump / long jump )': true,
    qualifying_knockout: true,
    'qualifying round - knockout': true,
  };
  if (row.match.advanced_scoring && showAdvanceScoring)
    return (
      <>
        <div onClick={(e) => e.stopPropagation()}>
          <AdvancedDsComponent
            closeModal={() => setShowAdvanceScoring(false)}
            state={showAdvanceScoring}
            matchStatus={row?.match?.match_status?.toLowerCase()}
            MatchId={row.match.match}
          />
        </div>
      </>
    );
  if (
    row.match.advanced_scoring &&
    !matchStatus[row?.match?.match_status?.toLowerCase()] &&
    !yetToPlayStatus[row?.match?.match_status?.toLowerCase()]
  )
    return (
      <td
        className="px-2 py-2.5 text-xs cursor-pointer"
        style={{ color: 'rgba(255,255,255,0.38)' }}
      >
        <span
          className="cursor-pointer text-blue-kheloBlue400"
          onClick={(e) => {
            e.stopPropagation();
            setShowAdvanceScoring(true);
          }}
        >
          View Score{' '}
          {realTimeStatus[row?.match?.match_status?.toLowerCase()] && (
            <span className="ml-2 w-2 h-2 inline-block flex-shrink-0 bg-green-500 rounded-full"></span>
          )}
        </span>
      </td>
    );

  if (status && enableLink && row.match.match_status && links[row.event_format?.toLowerCase()]) {
    return (
      <Link
        to={`/public/score/${row?.event_format?.toLowerCase()}/${encodeURIComponent(
          row.event_name
        )}`}
      >
        {' '}
        <td
          className="px-2 py-2.5 text-xs cursor-pointer"
          style={{ color: 'rgba(255,255,255,0.38)' }}
        >
          <p>
            {yetToPlayStatus[row?.match?.match_status]?.toLowerCase()
              ? realTimeStatus[row?.match?.match_status]?.toLowerCase()
                ? 'View Live Score'
                : 'View Draw'
              : 'View Result'}
          </p>
        </td>{' '}
      </Link>
    );
  }
  if (
    (status && row.match.match_status && row.opponent_1[columnKey] === 'NA') ||
    (!row.opponent_1[columnKey] && row.opponent_1[columnKey] !== 0)
  ) {
    return (
      <td className="px-2 py-2.5 text-xs" style={{ color: 'rgba(255,255,255,0.38)' }}>
        <p>
          {yetToPlayStatus[row?.match?.match_status?.toLowerCase()]
            ? yetToPlayStatus[row?.match?.match_status?.toLowerCase()]
            : '-'}
        </p>
      </td>
    );
  }
  if (row.opponent_1[columnKey] === 'NA')
    return (
      <td className="pl-4 py-2.5 text-white text-xs">
        <p>-</p>
      </td>
    );
  return (
    <td className="pl-4 py-2.5 text-white text-xs">
      <p>
        {row.opponent_1[columnKey]} {row.opponent_2[columnKey]}
      </p>
    </td>
  );
}

function TimeColumn({ row, columnKey }) {
  if (row?.match?.match_status?.toLowerCase() !== 'bye')
    return (
      <td className="pl-4 py-2.5 text-blue-kheloBlue400 text-xs">
        <p>{getTimeInHHMM(row?.match[columnKey])}</p>
      </td>
    );
  return (
    <td className="pl-4 py-2.5 text-blue-kheloBlue400 text-xs">
      <p>-</p>
    </td>
  );
}

function AngleBottomColumn({
  row,
  columnKey,
  selected,
  setSelected,
  index,
  sport,
  selectedResultSport,
}) {
  const isKhelo = useShowKhelo();
  if (
    columnKey === 'angle_bottom' &&
    (selectedResultSport === 'All Sports' ||
      selectedResultSport === null ||
      selectedResultSport === '')
  ) {
    return (
      <>
        {row?.points?.points === 0 ? (
          <p></p>
        ) : (
          <td className="py-2.5 text-blue-kheloBlue400 text-xs text-center">
            {sport && sport !== 'All Sports' ? (
              ''
            ) : (
              <p
                id="send"
                className={`${selected === index ? (!isKhelo ? 'bg-blue-450' : 'bg-yellow-kheloDarkYellow') : ''
                  } leading-10 `}
              >
                {selected === index ? (
                  <FontAwesomeIcon
                    icon={faAngleUp}
                    className="w-2.5 h-2.5 pr-2 md:pr-0 cursor-pointer"
                  // onClick={() => {
                  //   setSelected(null);
                  // }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className="w-2.5 h-2.5 pr-2 md:pr-0 cursor-pointer leading-8"
                  // onClick={() => {
                  //   setSelected(index);
                  // }}
                  />
                )}
              </p>
            )}
            {selected ? (
              <div className={`bg-yellow-kheloDarkYellow  ${selected === index ? 'py-2' : ''} `}>
                {row.sports.map((sp, i) => (
                  <div
                    key={i}
                    className={`text-xs text-blue-kheloBlue400 ${selected === index ? 'leading-8 pb-2' : ''}`}
                  >
                    <p className="text-blue-kheloBlue400">{index === selected ? '\xa0' : null}</p>
                  </div>
                ))}
              </div>
            ) : null}
          </td>
        )}
      </>
    );
  } else
    return (
      <td className="py-2.5 text-blue-kheloBlue400 text-xs text-center">
        {''}
        {/* {selectedResultSport} {columnKey} */}
      </td>
    );
}

export default function Table({
  columns,
  rows,
  searchTerm = '',
  tableHeight,
  sport,
  icon,
  iconAndText,
  selectedResultSport,
  prependRow,
  isInfinite,
  fetchNext,
  isInfiniteFetching,
  hideFetchNext,
  rowCallback,
  firstRow,
}) {
  const [selected, setSelected] = useState(null);
  const isKhelo = useShowKhelo();
  const infiniteRef = useRef(null);
  useInfiniteScroll(infiniteRef, fetchNext, isInfiniteFetching, isInfinite);
  return (
    <div
      className={`overflow-x-auto w-full mt-4 md:mt-0 max-w-full border-collapse border border-gray-250 rounded-lg pb-3 ${isKhelo ? 'bg-yellow-kheloLightYellow' : 'bg-blue-dark'
        } text-blue-kheloBlue400`}
      style={tableHeight ? { maxHeight: `${tableHeight}`, overflowY: 'auto' } : null}
      ref={infiniteRef}
    >
      <table
        className={`w-full font-roboto whitespace-nowrap text-blue-kheloBlue400`}
        cellPadding="0"
        cellSpacing="0"
      >
        <thead>
          <tr
            className={`text-left sticky top-0 ${isKhelo ? 'bg-yellow-kheloDarkYellow' : 'bg-blue-dark'
              } headBorder z-1`}
          >
            {columns.map((column) => (
              <TableHeaderRow
                key={column.key}
                text={column.label}
                icon={icon}
                iconAndText={iconAndText}
                color="primary"
              />
            ))}
          </tr>
        </thead>
        <tbody ref={infiniteRef}>
          {firstRow || null}
          {!isInfinite &&
            rows?.map((row, index) => {
              return (
                <>
                  {prependRow && prependRow(row)}

                  <tr
                    data-cy="draws_table"
                    key={index}
                    onClick={() => {
                      rowCallback && rowCallback(row);
                    }}
                  // className="cursor-pointer"
                  // onClick={() => {
                  //   setSelected(selected === index ? null : index);
                  // }}
                  >
                    {columns.map((column, key) => {
                      return (
                        <>
                          <TableRenderRow
                            searchTerm={searchTerm}
                            index={index + 1}
                            key={column.key}
                            rowIndex={key}
                            column={column}
                            row={row}
                            selected={selected}
                            setSelected={setSelected}
                            sport={sport}
                            selectedResultSport={selectedResultSport}
                          />
                        </>
                      );
                    })}{' '}
                  </tr>
                </>
              );
            })}
          {isInfinite &&
            rows?.pages?.map((page) => {
              return page?.data?.map((row, index) => {
                return (
                  <>
                    {prependRow && prependRow(row)}
                    <tr
                      data-cy="draws_table"
                      key={index}
                      className="cursor-pointer border-b border-yellow-kheloDarkYellow"
                      id={index}
                      onClick={() => {
                        rowCallback && rowCallback(row);

                        selected === index + 1 ? setSelected(null) : setSelected(index + 1);
                      }}
                    >
                      {columns.map((column, key) => {
                        return (
                          <>
                            <TableRenderRow
                              index={index + 1}
                              searchTerm={searchTerm}
                              key={column.key}
                              rowIndex={key}
                              column={column}
                              row={row}
                              selected={selected}
                              setSelected={setSelected}
                              sport={sport}
                              selectedResultSport={selectedResultSport}
                            />
                          </>
                        );
                      })}{' '}
                    </tr>
                  </>
                );
              });
            })}
        </tbody>
      </table>
      {isInfinite && isInfiniteFetching && !hideFetchNext && (
        <div className="mx-auto">
          <Loader color="white" width={8} height={8} />
        </div>
      )}
    </div>
  );
}

function TableHeaderRow({ text, icon, iconAndText }) {
  const isKhelo = useShowKhelo();
  if (
    text === 'Gold' ||
    text === 'Silver' ||
    text === 'Bronze' ||
    text === 'Points' ||
    text === 'School Name' ||
    text === 'Institution' ||
    text === 'Pts'
  ) {
    if (icon) {
      if (text === 'Gold') {
        return (
          <th className="block-flex flex-nowrap items-center py-4 text-center">
            <div className="flex justify-center items-center gap-1">
              <img src={gold} alt="gold" className="inline-block w-5 h-5" />
              {iconAndText ? <p className="text-xs text-blue-kheloBlue400">{text}</p> : null}
            </div>
          </th>
        );
      } else if (text === 'Silver') {
        return (
          <th className="block-flex flex-nowrap items-center py-4 text-center">
            <div className="flex justify-center items-center gap-1">
              <img src={silver} alt="gold" className="inline-block w-5 h-5" />
              {iconAndText ? <p className="text-xs text-blue-kheloBlue400">{text}</p> : null}
            </div>
          </th>
        );
      } else if (text === 'Bronze') {
        return (
          <th className="block-flex flex-nowrap items-center py-4 text-center">
            <div className="flex justify-center items-center gap-1">
              <img src={bronze} alt="gold" className="inline-block w-5 h-5" />
              {iconAndText ? <p className="text-xs text-blue-kheloBlue400">{text}</p> : null}
            </div>
          </th>
        );
      } else if (text === 'Points') {
        return (
          <th className="block-flex flex-nowrap items-center py-4 text-center">
            <div className="flex justify-center items-center gap-1">
              {iconAndText ? (
                <p className="text-xs text-blue-kheloBlue400">{`${isKhelo ? 'Total' : text}`}</p>
              ) : null}
            </div>
          </th>
        );
      } else if (text === 'Pts') {
        return (
          <th className="block-flex flex-nowrap items-center py-4 text-center">
            <div className="flex justify-center items-center gap-1">
              <p className="text-xs text-blue-kheloBlue400">{text}</p>
            </div>
          </th>
        );
      } else if (text === 'Institution' || text === 'School Name') {
        return (
          <th className="w-auto md:w-56 lg:w-96 pl-4 py-4">
            <div className="flex flex-wrap items-center gap-1">
              {<p className="text-sm break-all text-blue-kheloBlue400">{`${isKhelo ? 'State' : text}`}</p>}
            </div>
          </th>
        );
      }
    }
    return (
      <>
        <th className="block-flex flex-nowrap items-center py-4 text-center">
          <span className="whitespace-nowrap text-blue-kheloBlue400 text-sm">{`${text === 'School Name' ? (isKhelo ? 'State' : text) : text
            }`}</span>
        </th>
      </>
    );
  } else
    return (
      <>
        <th className="block-flex flex-nowrap items-center pl-4 py-4">
          <span className="whitespace-nowrap text--blue-kheloBlue400 text-sm">{text}</span>
        </th>
      </>
    );
}

function TableRenderRow({
  row,
  column,
  selected,
  setSelected,
  index,
  sport,
  selectedResultSport,
  searchTerm,
}) {
  if (column.component === 'TextColumn')
    return (
      <TextColumn
        row={row}
        columnKey={column.key}
        selected={selected}
        setSelected={setSelected}
        index={index}
      />
    );
  if (column.component === 'TextAndImage')
    return (
      <TextAndImage
        row={row}
        columnKey={column.key}
        selected={selected}
        setSelected={setSelected}
        index={index}
      />
    );
  if (column.component === 'ResultButton') return <ResultButton row={row} columnKey={column.key} />;
  if (column.component === 'VideoColumn') return <VideoColumn row={row} columnKey={column.key} />;
  if (column.component === 'OpponentColumn')
    return <OpponentColumn row={row} columnKey={column.key} searchTerm={searchTerm} />;
  if (column.component === 'DateTimeColumn')
    return <DateTimeColumn row={row} columnKey={column.key} />;
  if (column.component === 'ResultMedalButton')
    return <ResultMedalButton row={row} columnKey={column.key} />;
  if (column.component === 'SportColumn')
    return (
      <SportColumn row={row} columnKey={column.key} showSubCategory={column.showSubCategory} />
    );
  if (column.component === 'VenueColumn') return <VenueColumn row={row} columnKey={column.key} />;
  if (column.component === 'ScoreColumn')
    return (
      <ScoreColumn
        row={row}
        columnKey={column.key}
        status={column.status}
        enableLink={column.enableLink}
        tournamentId={column.tournamentId}
      />
    );
  if (column.component === 'TimeColumn') return <TimeColumn row={row} columnKey={column.key} />;
  if (column.component === 'AngleBottomColumn')
    return (
      <AngleBottomColumn
        row={row}
        columnKey={column.key}
        selected={selected}
        setSelected={setSelected}
        index={index}
        sport={sport}
        selectedResultSport={selectedResultSport}
      />
    );
  if (column.component === 'RoundColumn') return <RoundColumn row={row} columnKey={column.key} />;
}
