import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek, faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
import gold from '../../assets/img/gold.svg';
import silver from '../../assets/img/silver.svg';
import bronze from '../../assets/img/bronze.svg';

export default function WinnerCard({ data }) {
  return (
    <div className="shadowBottomWhite shadow-shadowBottomWhite">
      <div className="flex gap-2.5 py-2 items-center">
        <div className="">
          {data.medal.toLocaleLowerCase() === 'gold' ? (
            <img src={gold} width="16px" height="16px" alt="" />
          ) : (
            ''
          )}
          {data.medal.toLocaleLowerCase() === 'silver' ? (
            <img src={silver} width="16px" height="16px" alt="" />
          ) : (
            ''
          )}
          {data.medal.toLocaleLowerCase() === 'bronze' ? (
            <img src={bronze} width="16px" height="16px" alt="" />
          ) : (
            ''
          )}
        </div>
        <div
          src={data.athlete.image}
          alt="dp"
          className="w-8 h-8 rounded-full inline-block mr-3 flex-shrink-0"
          style={{
            background: `url('${data.athlete.image}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className="flex justify-between w-full">
          <div className="flex-grow">
            <div className="mb-1.5 flex items-center  justify-between">
              {data.athlete.name.includes('Team') ? (
                <p className="text-xs text-blue-kheloBlue400">{data.athlete.institution}</p>
              ) : (
                <p className="text-xs text-blue-kheloBlue400">{data.athlete.name}</p>
              )}
            </div>
            <div className="mb-1.5">
              <span className="flex gap-2">
                <span className="flex gap-1 items-center text-xxs bg-blue-25 py-0.5 px-1 rounded text-blue-750">
                  <FontAwesomeIcon
                    icon={data.sport_event.gender === 'Male' ? faMars : faVenus}
                    className="w-2.5 h-2.5"
                  />
                  <p>{data.sport_event.gender}</p>
                </span>
                <span className="flex gap-1 items-center text-xxs bg-pink-250 py-0.5 px-1 rounded text-pink-850">
                  <FontAwesomeIcon icon={faCalendarWeek} className="w-2.5 h-2.5" />
                  <p>{data.sport_event.age_group}</p>
                </span>
              </span>
            </div>
            {/* <p className="text-xxs text-white">{data.athlete.institution}</p> */}
          </div>
          <div className="text-right">
            <p className="text-blue-kheloBlue400 text-xxs">{data.sport_event.sport}</p>
            <p className="text-blue-kheloBlue400 text-xxs text-opacity-70">{data?.sport_event?.sub_category}</p>
            <p className="text-blue-kheloBlue400 text-xxs text-opacity-70">{data?.athlete?.state}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
