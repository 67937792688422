import React, { useState } from 'react';

import Table from './Table';
import Loader from 'components/Loader';
import SearchIcon from '../../assets/img/search_icon.svg';
import FilterIcon from '../../assets/img/Filters.svg';
import SelectList from 'components/select-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DrawEventForm from './DrawEventForm';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom';
import { useSportEvent } from 'micro-site/queries/hooks';
import { useShowKhelo } from 'utils/HooksUtils';
import { fullRankingTableKheloText, fullRankingTableSFAText } from 'data/noDataText';
// import { getDateInMonthDateYear, getTimeIn12HourFormat } from '../../constants/DateFunctions';
import { GrandTotalMobile } from 'micro-site/Landing/Results/components/MainPage';

export default function MobileRankingTable({
  Points,
  Tabledata,
  selectedValue,
  setSelectedValue,
  placeholder,
  listData,
  sport,
  hideFilter,
  tableColumns,
  height,
}) {
  // console.log(Tabledata);
  const isKhelo = useShowKhelo();
  const [filterPopup, setFilterPopup] = useState(false);
  const filterPopupHandler = () => {
    setFilterPopup(!filterPopup);
  };
  const [params, setParams] = useState('');
  const [viewDrawsScreenMobile, setViewDrawsScreenMobile] = useState(false);
  const handleDrawFormat = (schema) => {
    setParams(schema);
  };
  const actualParams = useParams();
  const history = useHistory();
  const sportEventQueryData = useSportEvent({
    tournament: actualParams.tournament_id,
    sport: params?.sport,
    gender: params?.gender,
    age_group: params?.age_group,
    sport_category: params?.sport_category,
    sub_category: params?.sub_category,
  });
  const clearFilterHandler = () => {
    setSelectedValue('All Sports');
    setFilterPopup(false);
  };
  const monColumnsTable = tableColumns || [
    {
      key: 'institution',
      label: isKhelo ? 'District' : 'School Name',
      component: 'TextAndImage',
    },
    {
      key: 'gold',
      label: 'Gold',
      component: 'TextColumn',
    },
    {
      key: 'silver',
      label: 'Silver',
      component: 'TextColumn',
    },
    {
      key: 'bronze',
      label: 'Bronze',
      component: 'TextColumn',
    },
    {
      key: 'points',
      label: `${isKhelo ? 'Total' : 'Pts'}`,
      points: true,
      component: 'TextColumn',
    },
    {
      key: 'angle_bottom',
      label: '',
      component: 'AngleBottomColumn',
    },
  ];

  if (sportEventQueryData.isFetched === true && params) {
    try {
      history.push(
        `/public/score/${sportEventQueryData.data[0].digital_scoring_format?.toLowerCase()}/${encodeURIComponent(
          sportEventQueryData.data[0].sport_event
        )}`
      );
    } catch (error) {
      console.debug(error.message);
    }
  }

  return !viewDrawsScreenMobile ? (
    <>
      <div>
        {!hideFilter && (
          <div className="mb-3 mt-4 items-center md:hidden flex justify-between">
            <div className="flex gap-1">
              <img src={SearchIcon} className="hidden" alt="search icon" />
              <div onClick={filterPopupHandler} className="flex cursor-pointer items-end">
                <img src={FilterIcon} className="cursor-pointer w-5 h-5" alt="filter icon" />
                <p className="text-gray-450 text-sm">Filters</p>
              </div>
            </div>
            <div
              className="text-sm font-medium text-blue-350 cursor-pointer"
              onClick={() => setViewDrawsScreenMobile(true)}
            >
              View Results by Sport
            </div>
          </div>
        )}

        {!isKhelo && Points && (
          <div className="rounded-lg md:hidden w-full py-4 px-3 flex flex-col gap-1 border divide-y">
            {Points.map((point, idx) => (
              <div key={idx} className="py-3 w-full flex flex-col gap-2.5">
                <p className="font-bold text-sm">{point.label}</p>
                <div className="flex flex-row w-full items-center">
                  {point.medal.map((med, index) => (
                    <div key={index} className="w-1/3 flex gap-2 justify-center items-center">
                      <div className="">
                        <img src={med.img} width="22px" height="22px" alt="medal" />
                      </div>
                      <div>
                        {/* <p className='text-xs'>{med.label}</p> */}
                        <p className="text-xs font-bold">{med.count} Points</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="mt-4 ">
          {Tabledata?.data && Tabledata.data?.pages?.length && !Tabledata?.data?.error ? (
            <Table
              columns={monColumnsTable}
              rows={Tabledata?.data}
              icon={true}
              tableHeight={height}
              iconAndText={false}
              firstRow={<GrandTotalMobile data={Tabledata} />}
              // tableHeight={'500px'}
              isInfinite
              isInfiniteFetching={Tabledata.isFetchingNextPage}
              fetchNext={Tabledata?.fetchNextPage}
              selectedResultSport={selectedValue}
            />
          ) : Tabledata.isFetching ? (
            <Loader />
          ) : (
            <p className="w-full text-center my-20 text-sm text-gray-750">
              {isKhelo ? fullRankingTableKheloText : fullRankingTableSFAText}
            </p>
          )}
        </div>
        {!hideFilter ? (
          <div
            className={`fixed overflow-y-auto shadow-footerPopUp h-3/4 rounded-t-xl w-full bg-white flex flex-col justify-between px-4 py-6 bottom-0 right-0 left-0 z-20 ${filterPopup ? 'block' : 'hidden'
              } `}
          >
            <div className="flex justify-between">
              <h3 className="text-base font-bold gray-text-750">Filters </h3>
            </div>
            <div>
              <div className="mt-8">
                <div className="mb-4 w-full">
                  <p className="text-sm mb-1 ">Sport</p>
                  <SelectList
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    placeholder={placeholder}
                    listData={listData}
                    sport={sport}
                    fullWidth={true}
                    filterClose={true}
                    setFilterPopup={() => { }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-center text-sm md:text-base mt-auto sticky w-full bottom-0 py-2 bg-white">
              <button
                onClick={() => setFilterPopup(false)}
                className="btn btn-primary btn-md btn-block  bg-gray-750 hover:bg-blue-800 flex gap-1 items-center"
              >
                <FontAwesomeIcon icon={faCheck} />
                <p>Apply</p>
              </button>
              <button
                onClick={clearFilterHandler}
                className="flex gap-1 items-center justify-center cursor-pointer reg-btn-outline-dark mr-1 w-full hover:bg-gray-250 font-semibold rounded-lg mt-2 py-2 text-gray-dark"
              >
                <FontAwesomeIcon icon={faTimes} />
                <p>Clear</p>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  ) : (
    <div>
      <h1 className="text-base text-black font-bold my-3">View Draws by Sport</h1>
      <DrawEventForm
        handleDrawFormat={handleDrawFormat}
        isLoading={sportEventQueryData?.isLoading}
        customSubmitText={'View Results'}
      />
    </div>
  );
}
