import four from '../../assets/img/cm-trophy-home-page/CM PHOTO.png';
import five from '../../assets/img/cm-trophy-home-page/image 259.png';
import Img1 from '../../assets/img/cm-trophy-home-page/Periyar (1) 1.png';
import Img2 from '../../assets/img/cm-trophy-home-page/image 257.png';
import Img3 from '../../assets/img/cm-trophy-home-page/image 258.png';
import Img4 from '../../assets/img/cm-trophy-home-page/SDAT New English Logo 2024 High-01 2.png';
import Img5 from '../../assets/img/cm-trophy-home-page/TN GOVT LOGO 1.png';
import { Link, useHistory } from 'react-router-dom';
import useCustomStyling from 'hooks/useCustomStyling';

import { ReactComponent as Close } from '../../assets/img/cm-trophy-home-page/Close Circle.svg';
import { useState } from 'react';
import SecondaryHeader from './KheloHeader/SecondaryHeader';

export default function CMTrophyHeader({ forMicrosite = false, showOptions = true }) {
  // get route parameter
  // const { tournament_id: tournamentId } = useParams();

  const { isMobile } = useCustomStyling();
  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState(false);

  // showOptions
  //   ? !forMicrosite
  //     ? [
  //         {
  //           label: 'Home',
  //           path: '/',
  //           type: 'external',
  //         },
  //         {
  //           label: 'Categories',
  //           path: '#categories',
  //           type: 'internal',
  //         },
  //         {
  //           label: 'Sports',
  //           path: '#sports',
  //           type: 'internal',
  //         },
  //         // {
  //         //   label: 'Venues',
  //         //   path: '/micro-site/242/landing/venues',
  //         //   type: 'external',
  //         // },
  //         {
  //           label: 'Gallery',
  //           path: '#gallery',
  //           type: 'internal',
  //         },
  //         {
  //           label: 'About Us',
  //           path: '#about-us',
  //           type: 'internal',
  //         },
  //       ]
  //     : [
  //         {
  //           label: 'Home',
  //           path: '/',
  //           type: 'external',
  //         },
  //         {
  //           label: 'Sports',
  //           path: '/micro-site/' + tournamentId + '/landing/sports',
  //           type: 'external',
  //         },
  //         // {
  //         //   label: 'Venues',
  //         //   path: '/micro-site/242/landing/venues',
  //         //   type: 'external',
  //         // },
  //         {
  //           label: 'Schedule',
  //           path: '/micro-site/' + tournamentId + '/landing/schedule',
  //           type: 'external',
  //         },
  //         // {
  //         //   label: 'Gallery',
  //         //   path: '/#gallery',
  //         //   type: 'internal',
  //         // },
  //         {
  //           label: 'Results',
  //           path: '/micro-site/' + tournamentId + '/landing/results',
  //           type: 'external',
  //         },
  //       ]
  //   : [];

  const closeSideBar = () => setMenuOpen(false);

  return (
    <>
      <header
        className="bg-cm-blu text-white flex items-center justify-center px-3 md:px-4 xl:px-16 "
        style={{ height: isMobile ? '78px' : '80px' }}
      >
        <div className="container mx-auto">
          <div className={`grid items-center grid-cols-3 gap-1`}>
            <Link to="/" className="flex items-center gap-2 justify-start">
              <img
                src={Img1}
                alt="Emblem"
                className=" rounded-full "
                style={{ width: isMobile ? '40px' : '60px', height: isMobile ? '40px' : '60px' }}
              />
              <img
                src={Img2}
                alt="SDAT Logo"
                className=""
                style={{ width: isMobile ? '40px' : '60px', height: isMobile ? '40px' : '60px' }}
              />
              <img
                src={Img3}
                alt="Stylized Logo"
                className=""
                style={{ width: isMobile ? '40px' : '60px', height: isMobile ? '40px' : '60px' }}
              />
            </Link>
            {!showOptions && (
              <>
                <p className="text-xl font-bold text-gray-15 hover:text-gray-20 ignore-style hidden md:block">
                  {'Sports Development Authority of Tamil Nadu'}
                </p>
                <p className="text-xl font-bold text-gray-15 hover:text-gray-20 ignore-style block md:hidden">
                  {'SDAT'}
                </p>
              </>
            )}
            {showOptions && (
              <Link to={'/'} className="w-full flex items-center justify-center gap-2">
                <img
                  src={Img4}
                  alt="Emblem"
                  className="object-contain "
                  style={{
                    width: isMobile ? '40px' : '70px',
                    height: isMobile ? '40px' : '70px',
                  }}
                />
                <img
                  src={Img5}
                  alt="Emblem"
                  className="object-contain "
                  style={{
                    width: isMobile ? '40px' : '70px',
                    height: isMobile ? '40px' : '70px',
                  }}
                />
              </Link>
            )}

            {/* {showOptions && (
              <ul className=" h-16" style={{ display: isMobile || isTablet ? 'none' : 'flex' }}>
                {menuItems.map((item, index) => {
                  return item.type === 'internal' ? (
                    <a
                      href={item.path}
                      key={index}
                      className={'flex items-center justify-center p-3 px-4 '}
                    >
                      <li
                        className={`text-[16px] ${
                          pathname === item.path ? 'font-semibold' : 'font-light'
                        } text-gray-15 hover:text-gray-20 ignore-style font-poppins`}
                      >
                        {item.label}
                      </li>
                    </a>
                  ) : (
                    <Link
                      to={item.path}
                      key={index}
                      className={'flex items-center justify-center p-3 px-4'}
                    >
                      <li
                        className={`text-[16px] ${
                          pathname === item.path ? 'font-semibold' : 'font-light'
                        } text-gray-15 hover:text-gray-20 ignore-style font-poppins`}
                      >
                        {item.label}
                      </li>
                    </Link>
                  );
                })}
              </ul>
            )} */}
            <div className="flex items-center justify-end gap-2">
              <img
                src={five}
                alt="Profile 2"
                className=" rounded-full "
                style={{ width: isMobile ? '40px' : '60px', height: isMobile ? '40px' : '60px' }}
              />
              <img
                src={four}
                alt="Profile 1"
                className=" rounded-full object-contain"
                style={{ width: isMobile ? '40px' : '60px', height: isMobile ? '40px' : '60px' }}
              />
              { window.location.pathname !== '/access-profile' &&
              <button
                onClick={() => {
                  history.push('/access-profile');
                }}
                className="border hidden lg:block p-2 rounded-lg font-medium font-poppins text-xs whitespace-pre-wrap text-center text-white"
                style={{ borderColor: 'rgba(255, 255, 255, 0.2)', width: '100px' }}
              >
                Access Player Profile
              </button>
            }
            </div>
          </div>
        </div>
        {/* <div className="h-1 bg-[#87CEEB]"></div> */}
      </header>
      {menuOpen && (
        <button
          className="fixed top-0 flex items-center justify-center z-20  right-0 bg-white"
          style={{ width: '79px', height: '79px' }}
          onClick={closeSideBar}
        >
          <Close />
        </button>
      )}

      {showOptions && <SecondaryHeader showOptions={showOptions} />}
    </>
  );
}
