const PrivacyPolicy = () => {
  return (
    <div className="max-w-2xl mx-auto my-8 p-2 sm:p-0">
      <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p className="text-justify">
          The SFA Sporting Services Private Limited (“Company”/ “SFA”/ “we”/ “us”/ “our”) is a
          company incorporated and validly subsisting under the applicable laws of India, having its
          registered address at, 17A, Raviraj House, 5th Floor, Saraswati Road, Off Linking Road,
          Santacruz (West), Mumbai – 400054, and is the owner of this Website and all its variants
          (“Website”) The Company through this Website aims to promote its business of organizing,
          operating and managing a multi-sport Olympic-style championship across 27 disciplines of
          sport (“Championship”) and other similar sporting events to be hereinafter collectively
          referred to as (“SFA Events”), which aims to provide grassroot development of young
          athletes, by providing them with a platform to showcase their talents and have an
          opportunity to train with the best coaches in top training facilities of the country. This
          privacy policy (“Privacy Policy”) contains how we collect, use, store and process any
          personal data, information, content or communication of the user of this Website,
          competitors, athletes, parents, guardians, sports organizations, schools, federations,
          corporations, institutions in such context and right as may be applicable to them (“you”,
          “your”, “yourself”) submitted or collected on this Website with your due consent. By
          browsing, accessing, signing up, registering and/or by accepting any information or waiver
          on this Website, it is assumed that you have expressly consented to the collection, use,
          sharing, transfer, storage, disclosure and other uses of your information (including but
          not limited to personal and non-personally identifiable information) in accordance with
          this Privacy Policy. By browsing, accessing, signing up, registering and/or by accepting
          any information or waiver on this Website, it is assumed that you have read this Privacy
          Policy and are submitting any personal data or information with due consent. By accessing
          and using this Website and/or various features available and present herein, you accept
          and confirm your acceptance to the practices and standard operating procedure as described
          in this Privacy Policy. Through your use of this Website it is assumed that you have read
          and understood this Privacy Policy along with the terms and conditions of use thereof
          (“Terms of Use”). SFA recognizes that privacy is important and that individuals have a
          right to control their personal information. The following Privacy Policy governs how SFA
          handles your personal information. Please keep in observance that this Website may contain
          links to other websites and/or third-party portals, gateways, persons or websites not
          owned or controlled by us and we would in no case be responsible for the privacy practices
          of the linked third-party persons or websites. We encourage you to be aware when you leave
          our Website and to read the privacy policies of other websites that may collect your
          personal information. Our principles for collecting, storing, using and processing
          personal data or information through this Website are based on: a) Fairness and
          Lawfulness: When we process personal data or information, the individual rights of the
          users are protected. All personal data and information so collected and processed through
          this Website shall always be in a manner, which is legal and fair. b) Restricted to a
          specific purpose: The personal data of the user of this Website is processed only for
          specific purposes including but not limited to, associating with SFA, registration for
          participation in any SFA Events etc. and in relation to improving other services and
          products offered on this Website. c) Transparency: We intend to be transparent about our
          use, processing and collecting of any personal data and information, and inform the user
          of how their personal data is being collected, processed and used. Further, this Privacy
          Policy should be read in conjunction and together with the Terms of Use applicable to the
          service (Website or other SFA services) you are using. This Privacy Policy shall come into
          effect from the date of launching of this Website for use by the users and shall be in
          force for a period until this Website is active, available and/ or accessible by users on
          any such browser, server, marketplace, software or otherwise.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. COLLECTION OF PERSONAL INFORMATION:</h2>
        <p className="text-justify">
          Personal information or personally identifiable information (“PII”) is information that
          identifies (whether directly or indirectly) a particular individual or natural person,
          such as the individual’s name, postal address, email address, and mobile number indicating
          to that particular person or is identifiable. When anonymous information is directly or
          indirectly associated with personal information, the resulting information can also be
          treated as personal information. SFA does not collect personal information unless the
          information is necessary for one or more of its services, functions or activities. SFA
          will also only collect personal information by lawful and fair means and not in an
          unreasonably intrusive way. SFA shall require certain PII, when you wish to engage,
          participate or associate with SFA, or at other times through various enrolment forms,
          physical and online forms, waivers or through any other forms that may be required. You
          acknowledge that, providing SFA with information about yourself, is voluntary and is
          necessary for any engagement with SFA or participation in any SFA Events, and such persons
          may choose not to provide certain information, but then such persons may not be able to
          take advantage of or participate in any of SFA’s Services. The kinds of PII, SFA may
          collect from you, generally comprises of name, address, age group, gender, photographs,
          school details, contact details (including phone, fax and email), general preferences,
          medical / fitness related details and information, collected primarily for the purpose
          engaging with SFA or participation in any SFA Events, in the course of SFA’s business. We
          also collect information from and about the computers, phones, and/or other devices that
          you may use our Website from, and such information shall include, information that you may
          allow us to receive through device settings that you turn on, such as access to your GPS
          location, camera, photos ISP, language, time zone, mobile phone number, IP address and we
          shall only use such information to enhance the features and user experience on our
          Website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. COOKIES</h2>
        <p className="text-justify">
          We may use "cookies", "web beacons" and other similar tracking technologies to collect
          non-PII automatically as you visit and use this Website. Cookies are pieces of information
          that a website either transfers to your computer's hard drive or stores temporarily in
          your computer's memory for record-keeping purposes. We may use cookies for a number of
          purposes. For example, we may use cookies to track usage or behavioural patterns on this
          Website or to analyse web activity in accordance with the applicable laws and this Privacy
          Policy, however, we do not save any PII in cookies. This is done in order to personalize
          and enhance your experience with us, and services offered by us. Cookies help us provide,
          protect and improve this Website and our services there under, such as by personalizing
          content, tailoring and measuring advertisements, and providing you a safer and seamless
          experience on this Website. Cookies in this Website are used for the following purposes:
          a) Authentication: We use cookies to verify your account and determine when you have
          logged in so we can make it easier for you to access the services in this Website and help
          you use the appropriate experience and features on this Website. b) Website Security and
          Integrity: We use cookies to help us keep your profile, account, data and this Website
          safe and secure. We also use cookies to combat activity that violates this Privacy Policy
          and/or Terms of Use of the Website. c) Advertising, recommendations, insights and
          measurement: We use cookies to help us show advertisements and make recommendations for
          businesses and other organisations in relation to the products, services you may be
          interested in. We also use cookies to help provide you with content relevant to your
          locale, preferences and taste. Most browsers are initially set up to accept cookies. You
          can reset your browser to refuse all cookies or to indicate when a cookie is being sent.
          However, if you decline to accept cookies, it may hinder your use of certain functions of
          this Website. SFA uses cookies for tracking the statistics of their website. This allows
          SFA to better understand the users and improve the functionality of the Website. This
          tracking is conducted in such a way to ensure the anonymity of visitors to mean that the
          cookies may identify the user’s IP address or browser, but it shall not identify the user.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. USE OF PERSONAL INFORMATION</h2>
        <p className="text-justify">
          The PII collected from you by SFA is used to, provide you with SFA services including but
          not limited to, access to the Website, engagement with SFA, participation in SFA Events
          etc. This will include use of personal information for establishing and maintaining your
          membership record (if relevant), or to enrol you in SFA Events, courses, or competitions;
          and/or notify you about the services and promotions offered by SFA and its sponsors.
          Information provided will be used in various methods of communication apart from
          participation in SFA Events, including but not limited to direct mail, advertising, email,
          SMS, phone contact. SFA may use the personal information they collect and store for the
          purposes as mentioned herein after obtaining due consent. SFA also carry out research
          using personal information from time to time, in order to improve the quality of SFA
          Events. The analysis is carried out using aggregated information, not information relating
          to specific individuals. As a sporting event organiser, SFA enters into sponsorship
          arrangements with third parties from time to time. This will mean that SFA uses personal
          information about you to provide any partners, sponsors, affiliates or other parties
          associated with SFA, the information about SFA Events offered by SFA. If at any time you
          do not wish to be notified about the products, services or promotions offered by SFA’s
          sponsors, you can choose to opt out of such services. It is hereby acknowledged by SFA,
          that it does not provide, sell, rent or trade any personal information collected by you,
          directly to its sponsors or commercial partners. The information collected by SFA is used
          to fulfil your requests, to develop, protect, maintain, notify you of, and improve, SFA
          programs, services, including but not limited to this Website, organising SFA Events,
          developing young talent, promoting various sports, and advertising other SFA services. SFA
          may use the collected information to provide efficient customer support, conduct analysis
          and to prevent you from facing any technical issues in relation to the services offered by
          SFA. a) User Related Information: SFA uses the information that they may collect to verify
          you and your activities in relation to SFA Events, maintain the integrity of the services
          offered by SFA, promote your safety and security by investigating any suspicious activity
          or breaches of this Privacy Policy. b) Location Related Information: SFA may also use
          location related information only in a case where you have enabled access to information
          to communicate and improve the services SFA offers to you. c) Advertisements and other
          Sponsored Content: SFA may use the information it collects about and from you including
          information about your interests, actions and connections to select and personalize
          advertisements, promotional offers and other sponsored advertising that SFA may engage in.
          d) Product Research and Development: SFA uses the information collected to develop, test
          and improve their services, including but not limited by conducting surveys, research,
          testing, scouting and management of services.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. SHARING OF PERSONAL INFORMATION</h2>
        <p className="text-justify">
          We may share the information we collect from you in accordance with this Privacy Policy
          and all applicable laws through: a) Sharing within this Website: We may share your
          information to enable you to use the functions, services, and content on this Website. The
          people, accounts and organisations that interest you and for you to connect and
          communicate with such people, accounts and organisations on this Website as per the
          audience, social group, and privacy preferences chosen by you. b) Public Information and
          Content that others share or re-share about you: People who can see your activity or any
          public information that is listed by due consent or you may create, post or share on our
          Website can choose to share or create content relating to you and re-share it with others
          on and off our Website, including people, coaches, sponsors, partners, and businesses. If
          you wish to contain such sharing of public information, you may report the content or such
          activity and intimate SFA of the same. c) Third Party Websites, Websites and Integrations
          on this Website: When you choose to use third-party apps, websites or other services, that
          use, or are integrated with our Website including but not limited to advertisers,
          analytical partners, businesses or sponsored partners who offer goods and services to you
          on this Website, third- party payment and service vendors, researchers and law enforcement
          authorities, may receive information about what is listed about you, or posted or shared
          by you. Information collected by these third-party websites, and integration services is
          subject to their own terms and policies, and we are not liable for their use of the
          information subject to our security and data protection norms along with these Privacy
          Policy. d) New Owner: If the ownership or control of all or part of our Website or its
          assets change, we may transfer your information to the new owner only in accordance with
          this Privacy Policy that you have consented to. When you disengage with SFA and withdraw
          consent of use of your personal information, we remove all data, information, images,
          videos and other content or that may be listed on this Website, or you may have posted,
          shared such as your images, audio-video updates and other content on this Website, and you
          won't be able to recover this information later. Information that others have shared about
          you is not a part of your account and won't be deleted but you can always choose to report
          such content. Personal information may be disclosed outside by SFA to outsource content
          and services in a lawful manner and the Personal information collected by SFA may be
          disclosed to third parties to whom SFA contracts out specialised functions (including
          mailing houses, photographers, printing companies and event organisers). SFA does not
          sell, rent or trade personal information to or with third parties.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. STORAGE OF PERSONAL INFORMATION</h2>
        <p className="text-justify">
          The security of your personal information is important to us. We strive to ensure the
          security of your information and to protect your personal information against unauthorized
          access or unauthorized alteration, disclosure or destruction. For this purpose, we adopt
          internal reviews of the data collection, storage, usage and collection and/or processing
          practices and security measures, including appropriate encryption and physical security
          measures to guard against unauthorized access to systems where we store your information.
          SFA takes steps to ensure the security of personal information held by it from such risks
          as loss or unauthorised access, destruction, use, modification or disclosure. SFA only
          permits your details to be accessed by authorised personnel, and it is a condition of
          employment that SFA employees maintain the confidentiality of personal information.
          Although we strive to protect your personal data, we cannot guarantee the security of your
          data while it is being transmitted to our Website; any transmission is at your own risk.
          Once we have received your information, we have commercially reasonable procedures and
          security features in place to reasonably endeavour to prevent unauthorized access.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. SENSTIVE PERSONAL INFORMATION</h2>
        <p className="text-justify">
          We do not collect sensitive personal data. The definition of sensitive personal data
          varies from country to country. We consider sensitive personal data to be any information
          about you that reveals your racial or ethnic origin, political opinions, religious or
          philosophical beliefs and trade union membership in addition to data concerning your
          health, genetic and biometric information and sex life or sexual orientation.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. PRIVACY OF MINORS</h2>
        <p className="text-justify">
          This Website is not intentionally designed to collect, exploit and or directed at Minors
          except for providing such Minors with information and entertainment in relation to the
          services provided by SFA. We do not knowingly permit any person who is a Minor to use the
          services or to provide any other personal information. The User will be asked to confirm
          his/her age (i.e. to ensure that the user is above the age of 18 years) while registering
          themselves for accessing and using the Website. If we become aware that any such personal
          information of persons less than 18 years of age has been collected on this Website
          without verified parental consent, then we will endeavour to take commercially reasonable
          steps to delete any such information and notify the parent. SFA considers it the
          responsibility of parents or legal guardians to monitor their children’s use of SFA’s
          services including but not limited to the use of this Website and participation in any SFA
          Events. Nevertheless, it is SFA’s internal policy to restrict collection and processing of
          any personal information of Minors, and SFA seeks to protect the PII of Minors in every
          capacity. SFA shall not be liable for any loss, damage or liabilities that may arise, due
          to unauthorised breach of security by any third-party. However, we consider it the
          responsibility of parents or legal guardians to monitor their children’s use of our
          services and participation in SFA Events. Nevertheless, it is our policy not to collect
          and process any personal information from children below 18 years of age without due
          consent from parents or legal guardians, or offer to send any promotional materials to
          persons in that category. Should a parent or guardian have reasons to believe that a minor
          has provided us with personal information without their prior consent, you can contact our
          customer support team to ensure that the personal information is removed from this Website
          immediately.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">9. ONLINE PRIVACY ISSUES</h2>
        <p className="text-justify">
          SFA will apply these Terms to all personal information it handles, whether collected
          online or otherwise. To the extent that these Terms apply to online privacy issues, it is
          to be read as forming part of the terms of use for the Website. the Website also collects
          other information which may or may not be personal information. For each visitor to the
          Website, our server automatically recognises and stores user data like domain name or
          Internet protocol address, the type of user’s Internet browser, etc. Websites linked to
          the Website are operated by third parties and are not controlled by or affiliated to or
          associated with SFA, unless expressly specified by SFA. SFA is not responsible for any
          form of transmission, whatsoever, received by the user from any linked website or
          third-party. Accordingly, SFA does not make any representations concerning the privacy
          practices or policies of such linked websites or terms of use of such websites, nor does
          SFA control or guarantee the accuracy, integrity or quality of the information, data,
          text, software, music, sound, photographs, graphics, videos, messages or other materials
          available on such linked website or any third-party.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">10. LINKS TO THIRD PARTY WEBSITES</h2>
        <p className="text-justify">
          This Website may also provide third-party advertisements, services and links to other
          websites, persons, businesses, portals and/or applications ("Linked Parties"). However, we
          do not provide any PII to these Linked Parties. Linked Parties including but not limited
          to the coaching services, training facilities, payment gateway, ticketing gateway, sale of
          merchandise are operated by third parties and are not controlled by or affiliated to or
          associated with this Website or us, unless expressly specified on this Website. We are not
          responsible for any form of transmission, whatsoever, received by you from any Linked
          Parties. Accordingly, We do not make any representations concerning the privacy practices
          or policies of such third parties or terms of use of such Linked Parties and their
          services or websites, nor do we control or guarantee the accuracy, integrity or quality of
          the information, data, text, software, music, sound, photographs, graphics, videos,
          messages or other materials available on such Linked Parties. The inclusion or exclusion
          does not imply any endorsement by us, of the Linked Parties, the Linked Party's provider
          or the information on the Linked Parties. The information provided by you to such Linked
          Parties and shall be governed in accordance with the privacy policies of such Linked
          Parties, and it is recommended that you review the privacy policy on any such Linked
          Parties websites prior to using such Linked Party services as we shall have no liability
          or responsibility towards such information given by you to these Linked Websites or the
          services so rendered to you.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">11. FORCE MAJEURE EVENT</h2>
        <p className="text-justify">
          A force majeure event shall mean any event that is beyond the reasonable control of ours
          and shall include, without limitation, sabotage, fire, flood, explosion, acts of God,
          civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts
          of government, etc. (“Force Majeure Event”). Notwithstanding anything contained in this
          Privacy Policy or the Terms of Use, we shall not be held responsible for any loss, damage
          or misuse of your personal information, if such loss, damage or misuse is attributable to
          a Force Majeure Event.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">12. GRIEVANCE REDRESSAL</h2>
        <p className="text-justify">
          In accordance with applicable laws and rules made thereunder, if you notice any
          discrepancies in the information provided or have any grievance against this
          Website/Company, you may contact our Grievance Officer. The name and contact details of
          the Grievance Officer are: Mr./Ms./Mrs. [*] Company: SFA Sporting Services Private Limited
          Contact No: [*] Email:[*]
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">13. CHANGE IN PRIVACY POLICY</h2>
        <p className="text-justify">
          This Privacy Policy is subject to change from time to time. We reserve the right, at our
          sole discretion, to modify this Privacy Policy from time to time in order to ensure
          compliance with applicable laws (“Updated Privacy Policy”). The Updated Privacy Policy
          shall be effective immediately and shall supersede this Privacy Policy. We will notify you
          of any changes to this Privacy Policy if the changes made to this Privacy Policy
          significantly affect your rights or as may be required by law. You shall be solely
          responsible for reviewing the privacy notice from time to time for any modifications. By
          continuing to use this Website after the Updated Privacy Policy has been published, you
          affirm your agreement to the Updated Privacy Policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">14. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
        <p className="text-justify">
          This Website is governed by and operated in accordance with the laws of India. Any
          dispute, or cause of action in relation to this Privacy Policy shall be settled amicably
          and harmoniously between the parties to such dispute, and in the event such efforts of
          amicable settlement is futile, the parties may resort to litigation only in accordance
          with the Indian laws and the courts in Mumbai, India shall have competent and exclusive
          jurisdiction to try and settle any such dispute. By using this Website, participating in
          any Website activities and/or providing us with your PII and any other information, you
          (a) provide us express consent to the collection and/or transfer and/or processing of any
          information to and in Mumbai, India, and (b) understand that we shall collect, transfer,
          store, process and/or deal with your information in accordance with this Privacy Policy
          and all applicable laws of India. Consequently, to the full extent permitted by law, you
          hereby waive any claims relating to the processing of your PII and any other information
          in accordance with this Privacy Policy that may arise under the laws and regulations that
          apply to you.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
